"use client";

// Components
import { LayoutAppHeader3Cols } from "@/client/components/layouts/layout-app-header-3cols";
import { NavbarProfile } from "@/client/domains/user/navigation/components/navbar-profile";
import { SearchBar } from "@/client/domains/search/search-bar";

export const HeaderShop = () => {
  return <LayoutAppHeader3Cols userMenu={<NavbarProfile />} main={<SearchBar />} />;
};
