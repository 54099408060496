export const POSTAL_CODE_PLACEHOLDER_FALLBACK = "1050-423, 16";

export const FALLBACK_MANAGER_PHONE_NUMBER = "+49 30 22026226";

export const BASKET_MAX_COUNT = 999;

export const CREDIT_CARD_PLACEHOLDERS = {
  cardNumber: "XXXX XXXX XXXX XXXX",
  expiryDate: "MM/YY",
  securityCodeThreeDigits: "XXX",
};
