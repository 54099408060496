import { HttpRequestParams, HttpResponseApiDataType } from "@/common/types/http";
import {
  BasketDeliveryOptionsType,
  BasketDeliveryCreateParamsType,
  BasketDeliveryInformationType,
  BasketCheckoutType,
  BasketOrderCreateType,
  BasketPaymentBankTransferType,
  BasketPaymentCreditCardCredentialsType,
  BasketPaymentCreditCardsType,
  BasketDeliveryInformationOptionsType,
  BasketProductsTableType,
  BasketProductUpdateType,
  BasketCreateParamsType,
  BasketProductsCountType,
  BasketOrderCreateParamsType,
  BasketOrderPaymentCreditCardRequestDataType,
  BasketOrderPaymentCreditCardType,
  CreditCardIDType,
  BasketOrderPaymentCreditCard3DSecureRequestDataType,
  BasketOrderPaymentCreditCard3DSecureType,
} from "./types";
import { http } from "@/client/transport/http";
import { ProductCartCreateParamsType } from "@/client/domains/search/products/types";

export const basketApi = Object.freeze({
  basketMassSearchProductsCreate: (payload: BasketCreateParamsType) => {
    const { data } = payload;
    return http.post<HttpResponseApiDataType<void>>("/basket/mass-search/add-products", data);
  },
  basketProductCreate: (payload: ProductCartCreateParamsType) => {
    const { data } = payload;
    return http.post<HttpResponseApiDataType>("/basket/add-product", data);
  },
  basketProductsFetch: () => {
    return http.get<HttpResponseApiDataType<BasketProductsTableType>>("/basket/products");
  },
  basketProductsCountFetch: () => {
    return http.get<HttpResponseApiDataType<BasketProductsCountType>>("/basket/products/count");
  },
  fetchCheckout: () => {
    return http.get<HttpResponseApiDataType<BasketCheckoutType>>("/basket/checkout");
  },
  createOrder: (payload: HttpRequestParams<BasketOrderCreateParamsType>) => {
    const { data } = payload;
    return http.post<HttpResponseApiDataType<BasketOrderCreateType>>("/basket/order/create", data);
  },
  createPaymentCreditCard: (
    payload: HttpRequestParams<BasketOrderPaymentCreditCardRequestDataType>,
  ) => {
    const { data } = payload;
    return http.post<HttpResponseApiDataType<BasketOrderPaymentCreditCardType>>(
      "/basket/payment/credit-card/pay",
      data,
    );
  },
  createPaymentSavedCreditCard: (
    payload: HttpRequestParams<BasketOrderPaymentCreditCardRequestDataType<CreditCardIDType>>,
  ) => {
    const { data } = payload;
    return http.post<HttpResponseApiDataType<BasketOrderPaymentCreditCardType>>(
      "basket/payment/credit-card/pay-with-saved-card",
      data,
    );
  },
  createPaymentCreditCard3DSecure: (
    payload: HttpRequestParams<BasketOrderPaymentCreditCard3DSecureRequestDataType>,
  ) => {
    const { data } = payload;
    return http.post<HttpResponseApiDataType<BasketOrderPaymentCreditCard3DSecureType>>(
      "/basket/payment/credit-card/three-ds-authentication",
      data,
    );
  },
  fetchDeliveryOptions: () => {
    return http.get<HttpResponseApiDataType<BasketDeliveryOptionsType>>("/basket/delivery-options");
  },
  createDelivery: (payload: BasketDeliveryCreateParamsType) => {
    return http.post<HttpResponseApiDataType>("/basket/delivery-information", payload.data);
  },
  fetchDeliveryInformation: () => {
    return http.get<HttpResponseApiDataType<BasketDeliveryInformationType>>(
      "/basket/delivery-information",
    );
  },
  fetchDeliveryInformationOptions: () => {
    return http.get<HttpResponseApiDataType<BasketDeliveryInformationOptionsType>>(
      "/basket/delivery-information-options",
    );
  },
  fetchPaymentBankTransfer: () => {
    return http.get<HttpResponseApiDataType<BasketPaymentBankTransferType>>(
      "/basket/payment/bank-transfer",
    );
  },
  fetchPaymentCreditCardCredentials: () => {
    return http.get<HttpResponseApiDataType<BasketPaymentCreditCardCredentialsType>>(
      "/basket/payment/credit-card/client-credentials",
    );
  },
  fetchPaymentCreditCards: () => {
    return http.get<HttpResponseApiDataType<BasketPaymentCreditCardsType>>(
      "/basket/payment/customer/credit-cards",
    );
  },
  updateBasketProduct: (payload: HttpRequestParams<BasketProductUpdateType>) => {
    const { data, id } = payload;
    return http.patch<HttpResponseApiDataType<BasketProductsTableType>>(
      `/basket/products/${id}`,
      data,
    );
  },
  deleteBasketProduct: (payload: HttpRequestParams) => {
    return http.delete<HttpResponseApiDataType<BasketProductsTableType>>(
      `/basket/products/${payload.id}`,
    );
  },
});
