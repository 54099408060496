"use client";

// Components
import { LayoutAppHeader3Cols } from "@/client/components/layouts/layout-app-header-3cols";
import { Title } from "@/client/components/ui/Typography/Title";
import { useTranslation } from "react-i18next";

type BasketPageTitlePropsType = {
  titleTextI18Key?: "pageTitle" | "creditCardsPageTitle";
};

export const HeaderBasket = ({ titleTextI18Key = "pageTitle" }: BasketPageTitlePropsType) => {
  const { t } = useTranslation("basket");

  return (
    <LayoutAppHeader3Cols
      main={
        <Title
          tag="h1"
          font="second"
          size="24"
          fontWeight="700"
          alignmentHorizontal="center"
          color="dark"
        >
          {t(titleTextI18Key)}
        </Title>
      }
      light
    />
  );
};
