export const basketKeys = {
  deliveryOptionsFetch: "basket-delivery-step-options-fetch",
  deliveryInformation: "basket-delivery-information-fetch",
  deliveryInformationOptions: "basket-delivery-information-options-fetch",
  deliveryInfo: "basket-delivery-info",
  paymentBankTransfer: "payment-bank-transfer",
  paymentCreditCardCredentials: "payment-credit-card-credentials",
  paymentCreditCards: "payment-credit-cards",
  checkout: "basket-checkout",
  orderCreate: "basket-checkout-create",
  basketMassSearchProductsCreate: "basket/mass-search/add-products",
  orderPaymentCreditCard: "basket/payment/credit-card/pay",
  orderPaymentSavedCreditCard: "basket/payment/credit-card/pay-with-saved-card",
  basketCreate: "basket-create",
  basketProducts: "basket/products",
  basketProductsCount: "basket/products/count",
  basketProductsUpdate: "basket-products-update",
  basketProductCreate: "basket/add-product",
} as const;
